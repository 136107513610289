import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/index.vue')
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/auth.vue')
  },
  {
    path: '/moreNews',
    name: 'moreNews',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/moreNews.vue')
  },
  {
    path: '/newsInfo',
    name: 'newsInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/newsInfo.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/search.vue')
  },
  {
    path: '/liveCouse',
    name: 'liveCouse',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/liveCouse.vue')
  },
  {
    path: '/appraisalCenter',
    name: 'appraisalCenter',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/appraisalCenter.vue')
  },
  {
    path: '/moreReal',
    name: 'moreReal',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/moreReal.vue')
  },
  {
    path: '/practiceList',
    name: 'practiceList',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/practiceList.vue')
  },
  {
    path: '/certificateQuery',
    name: 'certificateQuery',
    component: () => import(/* webpackChunkName: "about" */ '../views/certificateQuery/Index.vue')
  },
  {
    path: '/my',
    name: 'my',
    component: () => import(/* webpackChunkName: "about" */ '../views/my/index.vue'),
    children: [
      {
        path: 'information',
        name: 'information',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/information.vue')
      },
      {
        path: 'apply',
        name: 'apply',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/apply.vue')
      },
      {
        path: 'about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/about.vue')
      },
      {
        path: 'helpCenter',
        name: 'helpCenter',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/helpCenter.vue')
      },
      {
        path: 'activation',
        name: 'activation',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/activation.vue')
      },
      {
        path: 'authentication',
        name: 'authentication',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/authentication.vue')
      },
      {
        path: 'wrongQuestion',
        name: 'wrongQuestion',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/wrongQuestion.vue')
      },
      {
        path: 'certificate',
        name: 'certificate',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/certificate.vue')
      },
      {
        path: 'collectJobs',
        name: 'collectJobs',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/collectJobs.vue')
      },
      {
        path: 'studyPoint',
        name: 'studyPoint',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/studyPoint.vue')
      },
      {
        path: 'article',
        name: 'article',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/article.vue')
      }
    ]
  },
  {
    path: '/myCourse',
    name: 'myCourse',
    component: () => import(/* webpackChunkName: "about" */ '../views/my/course.vue')
  },
  {
    path: '/jobInfo',
    name: 'jobInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/job/info.vue')
  },
  {
    path: '/courses',
    name: 'coursesIndex',
    component: () => import(/* webpackChunkName: "about" */ '../views/courses/index.vue')
  },
  {
    path: '/courses/info',
    name: 'coursesInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/courses/info.vue')
  },
  {
    path: '/examination/center',
    name: 'center',
    component: () => import(/* webpackChunkName: "about" */ '../views/examination/center.vue')
  },
  {
    path: '/examination/selfTest',
    name: 'selfTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/examination/selfTest.vue')
  },
  {
    path: '/examination/practice',
    name: 'practice',
    component: () => import(/* webpackChunkName: "about" */ '../views/examination/practice.vue')
  },
  {
    path: '/examination/imitate',
    name: 'imitate',
    component: () => import(/* webpackChunkName: "about" */ '../views/examination/imitate.vue')
  },
  {
    path: '/examination/contrast',
    name: 'contrast',
    component: () => import(/* webpackChunkName: "about" */ '../views/examination/contrast.vue')
  },
  {
    path: '/news',
    name: 'newsInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/news/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      isShow: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes
})

export default router
